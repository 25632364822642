#projects-cards{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
}
#projects-filter-keyword{
    padding-left: 4px;
}
.projects-cards-card{
    max-width: 25vw;
    min-width: 20vw;
    height: 280px;
    padding-top: 20px;
    display: grid;
    grid-template-rows: 65% 35%;
    box-shadow: 0px 4px 4px rgba(159, 159, 159, 0.15);
}
@media (max-width: 1000px) {
    #projects-cards{
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .projects-cards-card{
        max-width: 40vw;
        min-width: 20vw;
    }
}
@media (max-width: 800px) {
    .projects-cards-card{
        max-width: 50vw;
        min-width: 38vw;
    }
}
@media (max-width: 680px) {
    .projects-cards-card{
        max-width: 80vw;
        min-width: 80vw;
    }
}