.projects-cards-card{
    cursor: pointer;
}

.card-hero-container{
    width: 100%;
    background-color: rgb(243, 243, 243);
}

.card-hero{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-metadata{
    width: 100%;
    height: 100% !important;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 100%;
}

.card-metadata-text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0 10px 10px;
    background-color: white;
    font-size: 14px;
}

.card-metadata-title,
.card-metadata-title:visited{
    font-weight: bold;
    color: black;
    text-decoration: none;
    margin: 0;
}

.card-metadata-text-more{
    display: flex;
    flex-direction: column;
}

.card-metadata-more-year{
    font-weight: 300;
}

.card-metadata-prof-container{
    width: 100%;
}

.card-metadata-prof-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}