.projects-filter-bubble{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
    background-color: rgb(192, 192, 192);
    box-shadow: 0px 4px 4px rgba(159, 159, 159, 0.15);
    font-size: 10px;
    border-radius: 25px;
    padding: 0 10px;
    margin-right: 20px;
}

.projects-filter-bubble-value{
    font-size: 14px;
}

.projects-filter-bubble-remove{
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    padding-left: 10px;
}