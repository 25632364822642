#projects-cards{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
}

.projects-cards-card{
    width: 30%;
    height: 280px;
    padding-top: 20px;
    display: grid;
    grid-template-rows: 65% 35%;
    box-shadow: 0px 4px 4px rgba(159, 159, 159, 0.15);
}

#projects-cards-more-container{
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    cursor: pointer;
}

#projects-card-hero{
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 20px;
    font-size: 24px;
}

#projects-cards-hero-text{
    font-weight: 700;
    background-image: linear-gradient(120deg, #da9a9a 0%, #da9a9a 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: 0 88%;
}

#projects-cards-more,
#projects-cards-more:visited{
    font-weight: 700;
    color: black;
    text-decoration: none;
    padding-bottom: 20px;
}

@media (max-width: 680px) {
    #projects-cards-hero-text{
        text-align: center;
        font-size: 18px;
        background-image: none;
        text-decoration: underline #da9a9a;
    }
}