#projects-filter-container{
    display: grid;
    grid-template-columns: 20% 15% 15% 30%;
    gap: 5%;
    margin: 20px 0 50px 0;
    height: 35px;
}

.projects-filter{
    outline: none;
    border: none;
    box-shadow: 0px 4px 4px rgba(159, 159, 159, 0.15);
    padding: 10px 0 10px 10px;
    background-color: white;
    color: #B9B9B9;
    font-size: 12px;
    padding: 10px 0;
}

.projects-filter > option{
    color: black;
}

#projects-filter-bubbles{
    display: flex;
    flex-direction: row;
}