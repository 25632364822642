@font-face{
    font-family: 'Montserrat';    
    src: url('./Fonts/Montserrat-Regular.ttf');
    font-weight: normal;
}

@font-face{
    font-family: 'Montserrat';
    src: url('./Fonts/Montserrat-Light.ttf');
    font-weight: 300;
}

*{
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
}

body{
    margin: 0;
    padding: 0;    
    background-color: #E5E5E5;
}

html, body, #root{
    min-height: 100%;
    scroll-behavior: smooth;
}

#app{
    display: flex;
    flex-direction: column;
    height: 100%;
}

#header{    
    /*display: flex;*/
    justify-content: center;
    position: sticky;
    top:0;
    width: 100%;
    height: 100px;
    background-color: white;
    text-transform: uppercase;
    /*z-index: 1;*/
}

#header-main{    
    width: 100%;
    height: 100%
}

#header-main-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 300px;
    padding-left: 10vw;
    height: 100%;
}
#header-main-content a:last-child{
    margin-right: 40px;
}
#header-logo{
    width: 115px;
    height: 100%;
    background-image: url('./Assets/eptm_projects-logo.png');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.header-external-link{
    color: #9D9D9D;
    font-size: 12px;
    text-decoration: none;
}

#footer{
    padding-left: 10vw;
    height: 50px;
    background-color: #232323;
    font-size: 12px;
}

#footer-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    width: 80vw;
    height: 100%;
}

#footer-external-links{
    display: flex;
    justify-content: space-between;
    width: 100px;
}

.footer-external-link{
    cursor: pointer;
}

.app-child-node{
    max-width: 80vw;
    min-width: 80vw;
    min-height: calc(100vh - 150px);
    /*padding-top: 100px;*/
    align-self: center;
}

@media (max-width: 600px) {
    #projects-filter-container{
        height: auto;
        grid-template-columns: none;
    }
}
@media (max-width: 780px) {
    #projects-pagination-container{
        bottom: auto;
        width: auto;
        grid-template-columns: none;
        grid-gap: auto;
        gap: auto;
    }
}
@media (max-width: 300px) {
    #header-main-content{
        padding-left:0px;
    }
    #footer{
        padding-left: 0px;
    }
    #footer-wrapper{
        width: 100%;
    }
    .app-child-node{
        max-width: 100vw;

    }
    .projects-cards-card{
        min-width: 100vw;
        max-width: 100vw;
    }
    .projects-pagination{
        max-width: 100vw;
    }

}