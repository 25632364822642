h2{
    font-weight: bolder;
}
h2,.singleProjectCategories,.singleProjectImage{
    text-align: center;
}
.singleProjectImage > img{
    min-height: 35vh;
    max-height: 40vh;
    max-width: 80vw;
    width: auto;
    height: auto;
}
.singleProjectDescription{
    max-width: 100%
}
.singleProjectCategories > p > a {
    text-decoration: none;
}