#lightbox-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(131, 131, 131, 0.3);
}

.light-close{
    cursor: pointer;
    z-index: 0;
    width: 100%;
    height: 100%;
    position:fixed;
}
#lightbox-close-button{
    cursor: pointer;
}

#lightbox-document-container{
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#lightbox-document{
    max-height: 90vh;
    width: 80vw;
    background-color: rgb(243, 243, 243);
    box-shadow: 0px 4px 4px rgba(159, 159, 159, 0.15);
}

#lightbox-document-inner{
    height: 100%;
}

h2{
    font-weight: bolder;
}

h2,
#lightbox-project-categories,
#lightbox-project-image{
    text-align: center;
    align-self: center;
}

#lightbox-project-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#lightbox-project-image > img{
    max-width: 100%;
    max-height: 50vh;
}

#lightbox-project-description{
    max-width: 100%;
    margin: 0 30px 30px 30px;
    overflow-y: scroll;
    max-height: 25vh;
}

#lightbox-project-categories > p > a {
    text-decoration: none;
}

.lightbox-document-arrows{
    z-index: 0;
    display: flex;
    justify-content: center;
    width: 10vw;
    align-items: center;
}

.lightbox-document-arrows-close{
    z-index: 0;
    position: fixed;
    width: 10vw;
    height: 100%;
}

.lightbox-document-arrows > img {
    z-index: 10;
    width: 3vw;
    cursor: pointer;
}

@media (max-width: 980px) {
    .lightbox-document-arrows > img {
        width: 10vw;

        cursor: pointer;
    }
}