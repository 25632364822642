#projects-pagination-container{
    margin-top: 5vh;
    bottom: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 10%;
    gap: 5%;
}

.projects-pagination {
    display: flex;
    justify-content: space-around;
    list-style: none;
    outline: none;
    padding: 10px 0 10px 0;
    background-color: white;
    color: #B9B9B9;
    font-size: 12px;
    box-shadow: 0px 4px 4px rgba(159, 159, 159, 0.15);
}

.projects-active-page > a{
    color: white;
    background-color: rgb(173, 173, 173);
}

.projects-pagination > li > a{
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
    border-radius: 2px;
}

#projects-pagination-perpage{
    margin: 12px 0;
    padding-left: 10px;
    color: #B9B9B9;
    border: none;
    box-shadow: 0px 4px 4px rgba(159, 159, 159, 0.15);
}