.error404{
    position: relative;
    top: 15vh;
}
.e404imageCont{
    justify-content: center;
    display: flex;
}
.e404titleCont, .e404redirectCont{
    text-align: center;
}
.internalImage{
    max-width: 300px;
}
.e404image{
    width: 100%;
}
.e404titleCont{
    position: relative;
    top: 1vh;
}
.e404title{
    font-size: 2.5em;
}
.e404redirectCont{
    position:relative;
    top:2vh
}
.e404redirectLink{
    text-decoration: none;
}