.projects-spinner {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 80px;
    height: 80px;
    align-self: center;
}

.projects-spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #adadad;
    border-radius: 50%;
    animation: projects-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #adadad transparent transparent transparent;
}
.projects-spinner div:nth-child(1) {
    animation-delay: -0.45s;
}
.projects-spinner div:nth-child(2) {
    animation-delay: -0.3s;
}
.projects-spinner div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes projects-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}  