.app-backtotop-wrapper{
    height: 42px;
    position: fixed;
    bottom: 5.5vh;
    right: 3vw;
}       
.app-backtotop-wrapper > img{
    height: 100%;
}
.hidden{
    display: none !important;
}