#noproject-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

#noproject-icon{
    width: 20vw;
    height: 20vh;
    background-image: url('../../Assets/NoProjects.svg');
    background-repeat: no-repeat;
    background-position: center;
}